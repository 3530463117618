<template>
  <div ref="chartRef" class="leads-dashboard-graph" />
</template>

<style lang="scss" scoped>
.leads-dashboard-graph {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>

<script>
/* https://echarts.apache.org/en/index.html */
/* @see https://echarts.apache.org/en/option.html#series-line */

import chartsMixin from '@/components/UI/Charts/charts.mixin'
import { formatedDate } from '@/utils/date.util'

export default {
  mixins: [chartsMixin],
  props: {
    graph: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  watch: {
    graph() {
      if (Object.keys(this.graph).length > 0) {
        this.updateOptions()
      }
    },
  },
  mounted() {
    if (Object.keys(this.graph).length > 0) {
      this.updateOptions()
    }
  },
  methods: {
    updateOptions() {
      this.options.tooltip.formatter = params => {
        return `
      <div><strong>${params[0].name}</strong></div>
      <div><span style="display: inline-block; margin-right: 8px; border-radius: 25px; width: 10px; height: 10px; background-color:${
        params[0].color
      }"></span>${params[0].seriesName}<strong style="margin-left: 24px;">${new Intl.NumberFormat(this.$i18n.locale, {
          style: 'currency',
          currency: this.graph.AMOUNT[0].currency,
          notation: 'compact',
          compactDisplay: 'short',
        }).format(params[0].value)}</strong></div>
      <div><span style="display: inline-block; margin-right: 8px; border-radius: 25px; width: 10px; height: 10px; background-color:${
        params[1].color
      }"></span>${params[1].seriesName}<strong style="margin-left: 24px;">${new Intl.NumberFormat(this.$i18n.locale, {
          notation: 'compact',
          compactDisplay: 'short',
        }).format(params[1].value)}</strong></div>
      `
      }
      this.options.xAxis.data = this.graph.QUANTITY.map(quantity =>
        formatedDate(quantity.period, 'L', this.$i18n.locale)
      )
      this.options.yAxis[0].axisLabel.formatter = value => {
        return new Intl.NumberFormat(this.$i18n.locale, {
          style: 'currency',
          currency: this.graph.AMOUNT[0].currency,
          notation: 'compact',
          compactDisplay: 'short',
        }).format(value)
      }
      this.options.yAxis[1].axisLabel.formatter = value => {
        return new Intl.NumberFormat(this.$i18n.locale, {
          notation: 'compact',
          compactDisplay: 'short',
        }).format(value)
      }
      this.options.series[0].data = this.graph.AMOUNT.map(quantity => quantity.total)
      this.options.series[1].data = this.graph.QUANTITY.map(quantity => quantity.total)
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '10px',
          right: '10px',
          bottom: '0',
          containLabel: true,
        },
        legend: {
          data: [this.$t('leads.dashboard.graph.value'), this.$t('leads.dashboard.graph.leads')], //trad
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            name: this.$t('leads.dashboard.graph.value'), //trad
            axisLabel: {},
            axisLine: {
              show: true,
              lineStyle: {
                color: '#4552E9',
              },
            },
            axisTick: {
              show: true,
            },
            nameTextStyle: {
              color: '#4552E9',
            },
          },
          {
            type: 'value',
            name: this.$t('leads.dashboard.graph.leads'), //trad
            minInterval: 1,
            axisLabel: {},
            axisLine: {
              show: true,
              lineStyle: {
                color: '#FA3333',
              },
            },
            axisTick: {
              show: true,
            },
            nameTextStyle: {
              color: '#FA3333',
            },
          },
        ],
        series: [
          {
            name: this.$t('leads.dashboard.graph.value'), //trad
            type: 'line',
            yAxisIndex: 0,
            data: [],
            symbol: 'circle',
            symbolSize: 10,
            itemStyle: {
              color: '#4552E9',
            },
            lineStyle: {
              width: 3,
            },
          },
          {
            name: this.$t('leads.dashboard.graph.leads'), //trad
            type: 'line',
            yAxisIndex: 1,
            data: [],
            symbol: 'circle',
            symbolSize: 10,
            itemStyle: {
              color: '#FA3333',
            },
            lineStyle: {
              width: 3,
            },
          },
        ],
      },
    }
  },
}
</script>
