var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',{attrs:{"full-height":true}},[_c('screen-card',{attrs:{"full-height":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-data-search',{ref:"fixedElement",attrs:{"element-fixed":_vm.elementFixed,"scroll-direction":_vm.scrollDirection,"is-mobile":_vm.isMobile,"is-tablet":_vm.isTablet,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrolling,"creation-roles":[_vm.userRoles.superAdmin, _vm.userRoles.brandAdmin, _vm.userRoles.agency],"view":"leads","with-search":true,"with-filter":true},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"filterSlot",fn:function(){return [_c('lead-list-filters',{on:{"onUpdateFilters":_vm.onUpdateFilters}})]},proxy:true}])}),_c('div',{staticClass:"leads-list__list"},[_c('ui-data-list',{attrs:{"items":_vm.leadsMobile,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrolling,"no-more-data":_vm.noMoreData},on:{"click":function($event){return _vm.goToLead({ row: { locationId: $event.locationId, id: $event.id } })}},scopedSlots:_vm._u([{key:"skeleton",fn:function(){return [_c('div',{staticClass:"leads-list__list__content"},[_c('skeleton-line',{attrs:{"height":"18px","width":"80%"}}),_c('skeleton-line',{attrs:{"height":"15px","width":"50%","margin-top":"3px"}})],1),_c('skeleton-icon',{attrs:{"margin-left":"16px"}})]},proxy:true},{key:"item",fn:function(slotProps){return [_c('div',{staticClass:"leads-list__list__content"},[_c('div',{staticClass:"leads-list__list__content__id"},[_vm._v(" "+_vm._s(slotProps.item.productName)+" ")]),_c('div',{staticClass:"leads-list__list__content__email"},[_vm._v(" "+_vm._s(slotProps.item.locationName)+" ")])]),_c('span',{staticClass:"leads-list__list__status",class:[("leads-list__table__status--" + (slotProps.item.locationStatus))]},[(
                  slotProps.item.locationStatus === 'DEALER_ACCEPTED' ||
                  slotProps.item.locationStatus === 'TMP_DEALER_ACCEPTED' ||
                  slotProps.item.locationStatus === 'DEALER_ACCEPTED_VIA_CUSTOMER'
                )?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.location.accepted'))+" ")]:(slotProps.item.locationStatus === '' || slotProps.item.locationStatus === null)?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.location.none'))+" ")]:[_vm._v(_vm._s(_vm.$t('leads.list.column.status.location.rejected')))]],2)]}}])})],1),_c('div',{staticClass:"leads-list__table"},[_c('ui-data-table',{attrs:{"columns":_vm.columns,"rows":_vm.leads,"total-rows":_vm.totalRows,"is-loading":_vm.isLoading || _vm.isUpdatingData,"pagination":_vm.leadsPagination},on:{"loadItems":function($event){return _vm.loadLeads(Object.assign({}, {filters: _vm.stringifiedSearchFilters}, $event), false, true)},"onCellClick":_vm.goToLead},scopedSlots:_vm._u([{key:"data-table-row",fn:function(props){return [_c('router-link',{staticClass:"leads-list__table__link",attrs:{"to":{
                name: 'Lead',
                params: { id: props.row['id'], locationId: props.row['locationId'], currentTab: 'general' },
              }}}),(props.column.field === 'id')?_c('span',[_c('div',{staticClass:"leads-list__table__block"},[_vm._v(_vm._s(props.row['id']))])]):_vm._e(),(props.column.field === 'email')?_c('span',[_c('div',{staticClass:"leads-list__table__block"},[_vm._v(_vm._s(props.row['email']))])]):_vm._e(),(props.column.field === 'productId')?_c('span',[_c('div',{staticClass:"leads-list__table__block"},[_vm._v(_vm._s(props.row['productId']))])]):_vm._e(),(props.column.field === 'url')?_c('span',[_c('div',{staticClass:"leads-list__table__block"},[_vm._v(_vm._s(props.row['locationName']))])]):_vm._e(),(props.column.field === 'productName')?_c('span',[_c('div',{staticClass:"leads-list__table__block"},[_vm._v(_vm._s(props.row['productName']))])]):_vm._e(),(props.column.field === 'createdAt')?_c('span',[_vm._v(" "+_vm._s(_vm.date(props.row['createdAt']))+" ")]):_vm._e(),(props.column.field === 'locationStatus')?_c('span',[_c('span',{staticClass:"leads-list__table__status",class:[("leads-list__table__status--" + (props.row['locationStatus']))]},[(
                    props.row['locationStatus'] === 'DEALER_ACCEPTED' ||
                    props.row['locationStatus'] === 'TMP_DEALER_ACCEPTED' ||
                    props.row['locationStatus'] === 'DEALER_ACCEPTED_VIA_CUSTOMER'
                  )?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.location.accepted'))+" ")]:(props.row['locationStatus'] === '' || props.row['locationStatus'] === null)?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.location.none'))+" ")]:[_vm._v(_vm._s(_vm.$t('leads.list.column.status.location.rejected')))]],2)]):_vm._e(),(props.column.field === 'customerStatus')?_c('span',[_c('span',{staticClass:"leads-list__table__status",class:[("leads-list__table__status--" + (props.row['customerStatus']))]},[(props.row['customerStatus'] === 'TMP_REASSIGN' || props.row['customerStatus'] === 'REASSIGN')?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.customer.reassign'))+" ")]:(
                    props.row['customerStatus'] === 'TMP_WONT_REASSIGN' ||
                    props.row['customerStatus'] === 'WONT_REASSIGN'
                  )?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.customer.noreassign'))+" ")]:(props.row['customerStatus'] === 'SATISFIED')?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.customer.satisfied'))+" ")]:(props.row['customerStatus'] === 'UNSATISFIED')?[_vm._v(" "+_vm._s(_vm.$t('leads.list.column.status.customer.unsatisfied'))+" ")]:[_vm._v(_vm._s(_vm.$t('leads.list.column.status.customer.created')))]],2)]):_vm._e()]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }