import * as echarts from 'echarts'

export default {
  mounted() {
    this.chart = echarts.init(this.$refs.chartRef)
    this.chart.setOption(this._options)

    window.addEventListener('resize', () => {
      if (this.$refs.chartRef) {
        this.chart.resize({
          width: this.$refs.chartRef.clientWidth,
        })
      }
    })
  },
  watch: {
    options: {
      deep: true,
      handler: 'updateChart',
    },
  },
  methods: {
    updateChart() {
      this.chart.setOption(this._options)
    },
  },
  computed: {
    _options() {
      return { ...this.options }
    },
  },
}
