<template>
  <screen-grid :full-height="true">
    <screen-card :full-height="true">
      <template v-slot:body>
        <ui-data-search
          :element-fixed="elementFixed"
          :scroll-direction="scrollDirection"
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :is-loading="isLoading"
          :is-scrolling="isScrolling"
          :creation-roles="[userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency]"
          view="leads"
          ref="fixedElement"
          @search="onSearch"
          :with-search="true"
          :with-filter="true"
        >
          <template v-slot:filterSlot>
            <lead-list-filters @onUpdateFilters="onUpdateFilters" />
          </template>
        </ui-data-search>
        <div class="leads-list__list">
          <ui-data-list
            :items="leadsMobile"
            :is-loading="isLoading"
            :is-scrolling="isScrolling"
            :no-more-data="noMoreData"
            @click="goToLead({ row: { locationId: $event.locationId, id: $event.id } })"
          >
            <template v-slot:skeleton>
              <div class="leads-list__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="leads-list__list__content">
                <div class="leads-list__list__content__id">
                  {{ slotProps.item.productName }}
                </div>
                <div class="leads-list__list__content__email">
                  {{ slotProps.item.locationName }}
                </div>
              </div>
              <span
                class="leads-list__list__status"
                :class="[`leads-list__table__status--${slotProps.item.locationStatus}`]"
              >
                <template
                  v-if="
                    slotProps.item.locationStatus === 'DEALER_ACCEPTED' ||
                    slotProps.item.locationStatus === 'TMP_DEALER_ACCEPTED' ||
                    slotProps.item.locationStatus === 'DEALER_ACCEPTED_VIA_CUSTOMER'
                  "
                >
                  {{ $t('leads.list.column.status.location.accepted') }}
                </template>
                <template v-else-if="slotProps.item.locationStatus === '' || slotProps.item.locationStatus === null">
                  {{ $t('leads.list.column.status.location.none') }}
                </template>
                <template v-else>{{ $t('leads.list.column.status.location.rejected') }}</template>
              </span>
            </template>
          </ui-data-list>
        </div>
        <div class="leads-list__table">
          <ui-data-table
            :columns="columns"
            :rows="leads"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdatingData"
            :pagination="leadsPagination"
            @loadItems="loadLeads({ filters: stringifiedSearchFilters, ...$event }, false, true)"
            @onCellClick="goToLead"
          >
            <template slot="data-table-row" slot-scope="props">
              <router-link
                class="leads-list__table__link"
                :to="{
                  name: 'Lead',
                  params: { id: props.row['id'], locationId: props.row['locationId'], currentTab: 'general' },
                }"
              />
              <span v-if="props.column.field === 'id'">
                <div class="leads-list__table__block">{{ props.row['id'] }}</div>
              </span>
              <span v-if="props.column.field === 'email'">
                <div class="leads-list__table__block">{{ props.row['email'] }}</div>
              </span>
              <span v-if="props.column.field === 'productId'">
                <div class="leads-list__table__block">{{ props.row['productId'] }}</div>
              </span>
              <span v-if="props.column.field === 'url'">
                <div class="leads-list__table__block">{{ props.row['locationName'] }}</div>
              </span>
              <span v-if="props.column.field === 'productName'">
                <div class="leads-list__table__block">{{ props.row['productName'] }}</div>
              </span>
              <span v-if="props.column.field === 'createdAt'">
                {{ date(props.row['createdAt']) }}
              </span>
              <span v-if="props.column.field === 'locationStatus'">
                <span
                  class="leads-list__table__status"
                  :class="[`leads-list__table__status--${props.row['locationStatus']}`]"
                >
                  <template
                    v-if="
                      props.row['locationStatus'] === 'DEALER_ACCEPTED' ||
                      props.row['locationStatus'] === 'TMP_DEALER_ACCEPTED' ||
                      props.row['locationStatus'] === 'DEALER_ACCEPTED_VIA_CUSTOMER'
                    "
                  >
                    {{ $t('leads.list.column.status.location.accepted') }}
                  </template>
                  <template v-else-if="props.row['locationStatus'] === '' || props.row['locationStatus'] === null">
                    {{ $t('leads.list.column.status.location.none') }}
                  </template>
                  <template v-else>{{ $t('leads.list.column.status.location.rejected') }}</template>
                </span>
              </span>
              <span v-if="props.column.field === 'customerStatus'">
                <span
                  class="leads-list__table__status"
                  :class="[`leads-list__table__status--${props.row['customerStatus']}`]"
                >
                  <template
                    v-if="props.row['customerStatus'] === 'TMP_REASSIGN' || props.row['customerStatus'] === 'REASSIGN'"
                  >
                    {{ $t('leads.list.column.status.customer.reassign') }}
                  </template>
                  <template
                    v-else-if="
                      props.row['customerStatus'] === 'TMP_WONT_REASSIGN' ||
                      props.row['customerStatus'] === 'WONT_REASSIGN'
                    "
                  >
                    {{ $t('leads.list.column.status.customer.noreassign') }}
                  </template>
                  <template v-else-if="props.row['customerStatus'] === 'SATISFIED'">
                    {{ $t('leads.list.column.status.customer.satisfied') }}
                  </template>
                  <template v-else-if="props.row['customerStatus'] === 'UNSATISFIED'">
                    {{ $t('leads.list.column.status.customer.unsatisfied') }}
                  </template>
                  <template v-else>{{ $t('leads.list.column.status.customer.created') }}</template>
                </span>
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { onScroll } from '@/mixins/scroll.mixin'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import LeadListFilters from './ListFilters'
import { userRoles } from '@/config/permissions.config'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LeadsList',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
    LeadListFilters,
  },
  mixins: [onScroll],
  data() {
    return {
      stringifiedSearchFilters: '',
      search: '',
      userRoles,
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalRows: {
      type: Number,
      required: false,
      default: 0,
    },
    noMoreData: {
      type: Boolean,
      required: false,
      default: false,
    },
    leads: {
      type: Array,
      required: true,
    },
    leadsMobile: {
      type: Array,
      required: true,
    },
    leadsPagination: {
      type: Object,
      required: true,
    },
  },
  watch: {
    search() {
      this.loadLeads({ filters: this.stringifiedSearchFilters }, true, true)
    },
    stringifiedSearchFilters() {
      this.loadLeads({ filters: this.stringifiedSearchFilters }, true, true)
    },
  },
  async mounted() {
    this.loadLeads(null, true)
  },
  computed: {
    noData() {
      return this.isLoading || this.noMoreData
    },
    columns() {
      return [
        {
          label: this.$t('leads.list.column.leadId'),
          field: 'id',
        },
        {
          label: this.$t('leads.list.column.client'),
          field: 'email',
        },
        {
          label: this.$t('leads.list.column.productId'),
          field: 'productId',
        },
        {
          label: this.$t('leads.list.column.location'),
          field: 'url',
        },
        {
          label: this.$t('leads.list.column.lead'),
          field: 'productName',
        },
        {
          label: this.$t('leads.list.column.creation'),
          field: 'createdAt',
        },
        {
          label: this.$t('leads.list.column.status.location.title'),
          field: 'locationStatus',
          sortable: false,
        },
        {
          label: this.$t('leads.list.column.status.customer.title'),
          field: 'customerStatus',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    onSearch(value) {
      this.search = value
    },
    onUpdateFilters(stringifiedFilters) {
      this.stringifiedSearchFilters = stringifiedFilters
    },
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY HH:mm', this.$i18n.locale)
    },
    goToLead(e) {
      this.$router.push({ name: 'Lead', params: { locationId: e.row.locationId, id: e.row.id } })
    },
    loadLeads(tableParams = null, resetLeads = false, updating = false) {
      this.$emit('loadLeads', {
        tableParams,
        resetLeads,
        updating,
        search: this.search,
      })
    },
  },
}
</script>

<style lang="scss">
.leads-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 4px 8px;
      white-space: nowrap;
      color: white;
      font-size: 11px;
      font-weight: 300;

      &:after {
        margin-left: 5px;
        content: '⬤';
      }

      &--SATISFIED,
      &--REASSIGN,
      &--TMP_REASSIGN,
      &--DEALER_ACCEPTED,
      &--TMP_DEALER_ACCEPTED,
      &--DEALER_ACCEPTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'success');
      }

      &--UNSATISFIED,
      &--WONT_REASSIGN,
      &--TMP_WONT_REASSIGN,
      &--DEALER_REJECTED,
      &--TMP_DEALER_REJECTED,
      &--DEALER_REJECTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'error');
      }

      &--LEAD_CREATION {
        background-color: map-get($generic-color-variants, 'warning');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__status {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 4px 8px;
      white-space: nowrap;
      color: white;
      font-size: 11px;
      font-weight: 300;

      &:after {
        margin-left: 5px;
        content: '⬤';
      }

      &--SATISFIED,
      &--REASSIGN,
      &--TMP_REASSIGN,
      &--DEALER_ACCEPTED,
      &--TMP_DEALER_ACCEPTED,
      &--DEALER_ACCEPTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'success');
      }

      &--UNSATISFIED,
      &--WONT_REASSIGN,
      &--TMP_WONT_REASSIGN,
      &--DEALER_REJECTED,
      &--TMP_DEALER_REJECTED,
      &--DEALER_REJECTED_VIA_CUSTOMER {
        background-color: map-get($generic-color-variants, 'error');
      }

      &--LEAD_CREATION {
        background-color: map-get($generic-color-variants, 'warning');
      }
    }
    &__block {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
