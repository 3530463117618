<template>
  <div class="leads-leads" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      :title="$t('leads.leads.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
    />
    <screen-container direction="column" class="leads-leads__tabscontainer">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab || 'dashboard'"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
    </screen-container>

    <!-- Dashboard Section -->
    <screen-container v-if="currentTab === 'dashboard'">
      <leads-dashboard
        :graph="dashboardGraph"
        :summary="dashboardSummary"
        :overview="dashboardOverview"
        :network="dashboardNetwork"
        :isLoading="isLoading"
        :stats-filter="statsFilter"
        @statsFilterChanged="updateDateRange"
        @networkFilterChanged="updateDateRangeNetwork"
        @overviewFilterChanged="updateOverviewGoal"
      />
    </screen-container>

    <!-- List Section -->
    <screen-container :full-height="true" v-if="currentTab === 'list' || !currentTab">
      <leads-list
        :leads="leads"
        :leads-mobile="leadsMobile"
        :leads-pagination="leadsPagination"
        :is-loading="isLoading"
        :is-updating-data="isUpdatingData"
        :is-scrolling="isScrolling"
        :total-rows="totalRows"
        :no-more-data="noMoreData"
        @loadLeads="loadLeads"
      />
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import LeadsDashboard from './Dashboard.vue'
import LeadsList from './List.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'

export default {
  name: 'Leads',
  components: {
    LeadsDashboard,
    LeadsList,
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isUpdatingData: false,
      isScrolling: false,
      totalRows: 0,
      noMoreData: false,
      userRoles,
      tabsElement: null,
      overviewGoal: '5',
      statsFilter: {
        id: 'global',
        label: this.$t('leads.dashboard.filters.global'),
      },
    }
  },
  async created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('dashboard')
    }

    this.isLoading = true
    await this.loadStats()
    await this.loadOverview()
    await this.loadNetwork()
    this.isLoading = false
  },
  computed: {
    ...mapState({
      leads: state => state.lead.leads,
      leadsMobile: state => state.lead.leadsMobile,
      leadsPagination: state => state.lead.leadsPagination,
      dashboardGraph: state => state.lead.dashboardGraph,
      dashboardSummary: state => state.lead.dashboardSummary,
      dashboardOverview: state => state.lead.dashboardOverview,
      dashboardNetwork: state => state.lead.dashboardNetwork,
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('leads.leads.breadcrumb'),
          route: {
            name: 'UserExpress',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('leads.leads.tabs.dashboard'),
          key: 'dashboard',
        },
        {
          label: this.$t('leads.leads.tabs.list'),
          key: 'list',
        },
      ]
    },
    userExpressAuthorized() {
      return this.userExpress.filter(user => {
        if (user.status === '1') {
          return user
        }
      })
    },
  },
  methods: {
    ...mapActions({
      getLeads: 'lead/getLeads',
      getGraph: 'lead/getGraph',
      getSummary: 'lead/getSummary',
      getOverview: 'lead/getOverview',
      getNetwork: 'lead/getNetwork',
    }),
    updateDateRange(e) {
      this.statsFilter = e
      this.loadStats()
    },
    updateOverviewGoal(e) {
      this.overviewGoal = e
      this.loadOverview()
    },
    updateDateRangeNetwork(e) {
      this.statsFilter = e
      this.loadNetwork()
    },
    async loadStats() {
      await this.getGraph({ view: 'day', dateRange: this.statsFilter.id })
      await this.getSummary({ dateRange: this.statsFilter.id })
      await this.getOverview({ dateRange: this.statsFilter.id, goal: this.overviewGoal })
    },
    async loadOverview() {
      await this.getOverview({ dateRange: this.statsFilter.id, goal: this.overviewGoal })
    },
    async loadNetwork() {
      await this.getNetwork({ dateRange: this.statsFilter.id })
    },
    async loadLeads(e) {
      if (e.updating) {
        this.isUpdatingData = true
      } else {
        this.isLoading = true
      }
      await this.getLeads({ tableParams: e.tableParams, resetLeads: e.resetLeads, search: e.search })
      this.setLeadsParams()
    },
    setLeadsParams() {
      if (this.leads.length > 0) {
        this.totalRows = this.leadsPagination.nbResults
      } else {
        this.noMoreData = true
      }
      this.isLoading = false
      this.isScrolling = false
      this.isUpdatingData = false
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route.name, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>

<style lang="scss">
.leads-leads {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__tabscontainer {
    padding-bottom: 0 !important;
  }
}
</style>
